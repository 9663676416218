<template>
  <page-container style="max-width: 600px;margin-bottom: 40px">
    <div class="title">Корзина</div>
    <template v-if="$store.state.user.cart.items.length">
      <div class="row" v-for="item in $store.state.user.cart.items"
           :key="item.type+item.item_id+item.tariff">
        <div class="name">{{ item.name }}</div>
        <div class="sum_block">
          <span style="font-size: 18px">{{ item.price }}&nbsp;₽</span>
          <span v-if="item.price_full!=item.price"
                style="text-decoration: line-through;font-size: 22px;margin-left: 10px">{{
              item.price_full
            }}&nbsp;₽</span>
          <span @click="$eventBus.emit('confirm', ['Удалить?', ()=>removeItem(item)])"
                style="font-size: 14px;margin-left: 10px;color: red;cursor:pointer;">Удалить</span>
        </div>
      </div>
      <div style="margin: 20px 0">
        <div style="font-size: 20px;margin-bottom: 10px">Дополнительные опции:</div>
        <div style="display: flex;align-items: center;gap: 40px;justify-content: space-between;"
             v-for="(group, index) in itemGroups"
             :key="index">
          <div>{{ group.name }}</div>
          <Button color="white" text_color="var(--primary-text-color)" style="font-size: 16px;width: 170px"
                  :style="$store.state.user.cart.items.find(item=>item.type===index)?'pointer-events: none;':''"
                  :text="$store.state.user.cart.items.find(item=>item.type===index)?'✓ Добавлено':`+ Добавить за ${group.price}&nbsp;₽`"
                  @click="$eventBus.emit('add-to-cart', {item_id: 1, type: index});"
          />
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px">
        <input placeholder="Промокод" v-model="promo">
        <Button @click="$eventBus.emit('add-to-cart', {promo})"
                v-if="promo!=($store.state.user.cart.promo?$store.state.user.cart.promo.code:'')" text="Применить"/>
        <div v-else-if="$store.state.user.cart.promo">Промокод "{{ $store.state.user.cart.promo.code.toUpperCase() }}"
          со скидкой {{ $store.state.user.cart.promo.percent }}%
        </div>
      </div>
      <div class="row" style="margin-bottom: 20px" v-if="hasGame">
        Внимание! Онлайн-версия является дополнением к оффлайн-версии. Инструкция входит в комплектацию только в тарифе
        "МАКСИМАЛЬНЫЙ" и отдельно не продаётся!
      </div>
      <div v-if="hasProduct">
        <h3 style="margin-bottom: 20px">Получатель</h3>
        <div style="display: flex;gap: 10px;flex-wrap: wrap">
          <div class="field">
            <label>ФИО</label>
            <input @change="saveOrder()" v-model="$store.state.user.cart.delivery_params.client_name" style="width: 250px"/>
          </div>
          <div class="field">
            <label>Телефон</label>
            <input @change="saveOrder()" v-model="$store.state.user.cart.delivery_params.client_phone" style="width: 250px"/>
          </div>
        </div>
      </div>
      <div style="display: flex;gap: 20px;align-items: center;margin-bottom: 20px;flex-wrap: wrap">
      <div v-if="$store.state.user.cart.delivery_params.address">
        Доставка "{{$store.state.user.cart.delivery_params.tariff.tariff_name}}" {{$store.state.user.cart.delivery_params.address.address}}
      </div>
        <Button v-if="hasProduct && !showSdek" style="max-width: 300px;" :text="`${$store.state.user.cart.delivery_params.address ? 'Изменить' : 'Выбрать'} параметры доставки`"
                @click="showSdek = true"/>

      </div>

      <Sdek v-if="hasProduct && showSdek" @change="params => saveOrder(params)"/>
      <div v-if="!hasProduct || ($store.state.user.cart.delivery_params.address.address && $store.state.user.cart.delivery_params.tariff.tariff_name)">
        <div class="row" style="border-top: 1px solid #3b89b5;padding-top: 20px;margin-bottom: 0">
          <div style="font-size: 24px" class="name">Итого к оплате</div>
          <div style="display: flex;align-items: center;">
            <span style="font-size: 20px">{{ $store.state.user.cart.price }}&nbsp;₽</span>
            <span v-if="$store.state.user.cart.price_full!=$store.state.user.cart.price"
                  style="text-decoration: line-through;font-size: 24px;margin-left: 10px">{{
                $store.state.user.cart.price_full
              }}&nbsp;₽</span>
          </div>
        </div>
        <div style="font-size: 16px" class="name">*Доставка оплачивается отдельно при получении</div>
        <div style="display: flex;align-items: center;gap: 10px;margin-top: 20px">
          <input v-model="agree" id="agree" type="checkbox">
          <label for="agree">Ознакомлен с <a href="/agreement" target="_blank">Пользовательским соглашением</a>, <a href="/agreement2" target="_blank">Соглашением на обработку перс. данных</a>, <a
              href="/offer" target="_blank">Офертой</a> и <a href="/program" target="_blank">Программой игр (техническим
            описанием)</a></label>
        </div>
        <Button :disabled="!agree" style="margin-top: 10px" v-if="+$store.state.user.cart.price"
                @click="redirectToPayment"
                text="Перейти к оплате"/>
      </div>
    </template>
    <div v-else style="width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;">
      Корзина пустая
      <Button @click="$router.push('/games/buy')" text="Перейти в магазин"/>
    </div>
  </page-container>
</template>

<script>
import Button from "@/components/Button";
import Sdek from "@/pages/Sdek.vue";

export default {
  name: "Cart",
  components: {Sdek, Button},
  data() {
    return {
      promo: '',
      agree: false,
      showSdek: false,
    }
  },
  watch: {
    '$store.state.user'() {
      try {
        this.promo = this.$store.state.user.cart.promo.code;
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    }
  },
  methods: {
    redirectToPayment() {
      this.$server.request('user/getPaymentLink', {price: this.$store.state.user.cart.price}, data => {
        location.href = data.response;
      }, data => this.$eventBus.emit('show-error', data.error || 'Нет соединения'));
    },
    removeItem(item) {
      this.$eventBus.emit('add-to-cart', {...item, remove: 1});
    },
    saveOrder(params = {}) {
      const delivery_params = this.$store.state.user.cart.delivery_params;
      for(let i in params) delivery_params[i] = params[i];
      this.$server.request('user/setDeliveryParams', {
        datetime_update: this.$store.state.user.cart.datetime_update,
        delivery_params
      }, (data) => {
        // this.$store.state.user.cart = data.response;
      }, data => this.$eventBus.emit('show-error', data.error || 'Нет соединения'));
    }
  },
  mounted() {
    this.$eventBus.emit('load-user');
    console.log(this.$store.state.user.cart);
  },
  computed: {
    itemGroups() {
      let result = {};
      for (let i in this.$store.state.user.cart.itemGroups) {
        let g = this.$store.state.user.cart.itemGroups[i];
        if (!g.type || this.$store.state.user.cart.items.find(item => item.type === g.type)) {
          result[i] = g;
        }
      }
      return result;
    },
    hasGame() {
      return this.$store.state.user.cart.items.find(item => item.type === 'game')
    },
    hasProduct() {
      return this.$store.state.user.cart.items.find(item => item.type === 'product')
    }
  }
}
</script>

<style scoped lang="scss">
.row {
  .name {
    font-size: 18px;
  }

  .sum_block {
    display: flex;
    align-items: center;
    //min-width: 190px;
  }

  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px
}

@media (max-width: 768px) {
  .row {
    .sum_block {
      align-items: flex-end;
      min-width: 80px;
      flex-direction: column;
    }
  }
}

.field {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-bottom: 20px;
}
</style>
